// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #006BA6;
  --ion-color-primary-rgb: 72,138,255;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #004B87;
  --ion-color-primary-tint: #4D97C1;

  /** secondary **/
  --ion-color-secondary: #54585A;
  --ion-color-secondary-rgb: 50,219,100;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #54585A;
  --ion-color-secondary-tint: #878A8C;

  /** tertiary **/
  --ion-color-tertiary: #fff;
  --ion-color-tertiary-rgb: 255,255,255;
  --ion-color-tertiary-contrast: #006BA6;
  --ion-color-tertiary-contrast-rgb: 72,138,255;
  --ion-color-tertiary-shade: #f4f4f4;
  --ion-color-tertiary-tint: #f5f5f5;

  /** success **/
  --ion-color-success: #00B16A;
  --ion-color-success-rgb: 0,177,106;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #01a764;
  --ion-color-success-tint: #01bd72;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #FD462A;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #f04227;
  --ion-color-danger-tint: #ff4f34;

  /** medium **/
  --ion-color-medium: #4D97C1;
  --ion-color-medium-rgb: 77,151,193;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #d7d7d7;
  --ion-color-medium-tint: #f5f5f5;

  /** light **/
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #CCCDCE;
  --ion-color-light-tint: #EEEEEF;
  
  /** dark **/
  --ion-color-dark: #1A3C4E;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #4D6775;
  --ion-color-dark-tint: #00263A;

}

:root,
:root[mode=ios],
:root[mode=md] {
  --ion-font-family: 'Roboto', sans-serif;
  font-family: 'Roboto', sans-serif;
  * {
    font-family: 'Roboto', sans-serif;
  }
}

@mixin ios-dark {
    /** primary **/
  --ion-color-primary: #006BA6;
  --ion-color-primary-rgb: 72,138,255;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #004B87;
  --ion-color-primary-tint: #4D97C1;

  /** secondary **/
  --ion-color-secondary: #54585A;
  --ion-color-secondary-rgb: 50,219,100;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #54585A;
  --ion-color-secondary-tint: #878A8C;

  /** tertiary **/
  --ion-color-tertiary: #fff;
  --ion-color-tertiary-rgb: 255,255,255;
  --ion-color-tertiary-contrast: #006BA6;
  --ion-color-tertiary-contrast-rgb: 72,138,255;
  --ion-color-tertiary-shade: #f4f4f4;
  --ion-color-tertiary-tint: #f5f5f5;

  /** success **/
  --ion-color-success: #00B16A;
  --ion-color-success-rgb: 0,177,106;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #01a764;
  --ion-color-success-tint: #01bd72;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #FD462A;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #f04227;
  --ion-color-danger-tint: #ff4f34;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
  
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #1c1c1c;
  --ion-item-background-activated: #313131;

  ion-title.title-large {
    --color: white;
  }
}

@mixin md-dark {
    /** primary **/
  --ion-color-primary: #006BA6;
  --ion-color-primary-rgb: 72,138,255;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #004B87;
  --ion-color-primary-tint: #4D97C1;

  /** secondary **/
  --ion-color-secondary: #54585A;
  --ion-color-secondary-rgb: 50,219,100;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #54585A;
  --ion-color-secondary-tint: #878A8C;

  /** tertiary **/
  --ion-color-tertiary: #fff;
  --ion-color-tertiary-rgb: 255,255,255;
  --ion-color-tertiary-contrast: #006BA6;
  --ion-color-tertiary-contrast-rgb: 72,138,255;
  --ion-color-tertiary-shade: #f4f4f4;
  --ion-color-tertiary-tint: #f5f5f5;

  /** success **/
  --ion-color-success: #00B16A;
  --ion-color-success-rgb: 0,177,106;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #01a764;
  --ion-color-success-tint: #01bd72;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #FD462A;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #f04227;
  --ion-color-danger-tint: #ff4f34;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1A1B1E;

  ion-title.title-large {
    --color: white;
  }
}

@media (prefers-color-scheme: dark) {

  .ios body.dark {
    @include ios-dark;
  }

  .md body.dark {
    @include md-dark;
  }
}

.ios body.dark {
  @include ios-dark;
}

.md body.dark {
  @include md-dark;
}